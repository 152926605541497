/* .navbar-container {
  margin-bottom: 150px;
} */
@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}
